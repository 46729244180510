@font-face {
    /* 사용할 폰트의 이름 */
    font-family: "SB_B";
    font-display: fallback;
  
    /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
       // url("./Roboto-Regular.woff") format("woff"),
    */

    src: url("./SB_B.ttf") format("truetype"), url("./SB_B.eot") format("embedded-opentype");
  
    /* 사용할 폰트의 기본 스타일 */
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    /* 사용할 폰트의 이름 */
    font-family: "SB_L";
    font-display: fallback;
  
    /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
       // url("./Roboto-Regular.woff") format("woff"),
    */

    src: url("./SB_L.ttf") format("truetype"), url("./SB_L.eot") format("embedded-opentype");
  
    /* 사용할 폰트의 기본 스타일 */
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    /* 사용할 폰트의 이름 */
    font-family: "SB_M";
    font-display: fallback;
  
    /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
       // url("./Roboto-Regular.woff") format("woff"),
    */

    src: url("./SB_M.ttf") format("truetype"), url("./SB_M.eot") format("embedded-opentype");
  
    /* 사용할 폰트의 기본 스타일 */
    font-weight: 400;
    font-style: normal;
  }