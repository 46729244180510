* {
  font-family: "SB_L";
}

.App-header {
  width: 100%;
  height: 90px;

  padding-top: 16px;
  padding-bottom: 16px;
  

  display: flex;
  align-items: center;
}

.App-logo {
  width: 120px;
  height: 66px;
}

.mainContent_area {
  width: 50%;

  /* display: flex;
  align-items: center; */
}